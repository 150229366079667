import styled from 'styled-components/macro';
import {
    Box,
    Heading,
    Button,
    Paragraph
} from '~styled';

const Terms = styled(Box)
`
padding-top: 20px;
padding-bottom: 20px;

@media (min-width: 768px){
    padding-top: 60px;
    padding-bottom: 60px;
}
@media (min-width: 992px){
    padding-top: 100px;
    padding-bottom: 100px;
}


`
Terms.Title = styled(Heading)
`
font-size: 32px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.4423;
margin-bottom: 60px;
margin-top: 60px;

@media (min-width: 576px) {
  font-size: 25px;
}
@media (min-width: 768px)
{
  font-size: 32px;
}
@media (min-width: 992px)
{
  font-size: 32px;
  margin-bottom: 100px;
}
`
Terms.TitleSmall = styled(Heading)
`
font-size: 21px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.42857;
margin-bottom: 15px;
@media (min-width: 992px){
    margin-bottom: 28px;
}
`
Terms.SubTitle = styled(Heading)
`
font-size: 16px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.42857;
margin-bottom: 15px;
@media (min-width: 992px){
    margin-bottom: 16px;
}
`
Terms.Text = styled(Paragraph)
`
font-size: 16px;
font-weight: 400;
letter-spacing: normal;
line-height: 1.777777;
@media (min-width: 992px){
    font-size: 18px;
}
`
Terms.List = styled(Box)
``


Terms.ListItem = styled(Paragraph)
`
font-size: 16px;
font-weight: 400;
letter-spacing: normal;
line-height: 1.777777;
@media (min-width: 992px){
    font-size: 18px;
}
`
Terms.ContentBox = styled(Box)
`
padding-top: 10px;
padding-bottom: 20px;

    table {

        border-spacing: 0;
        border: 1px solid black;
    
        tr {
          :last-child {
            td {
              border-bottom: 0;
            }
          }
        }
    
        th,
        td {
          margin: 0;
          padding: 1.5rem;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          color: #000;
    
          :last-child {
            border-right: 0;
          }
        }
      }

`
Terms.Content = styled(Box)
`
@media (min-width: 1200px){
    padding: 0 43px;
}
@media (min-width: 1400px){
    padding: 0 53px;
}
`


Terms.Box = styled(Box)
``


export default Terms;